<template>
  <ft-select
      :ref="item.uuid + 'tags'"
      :items="tagsSelect"
      v-model="item.tags"
      @input="updateTags"
      :add-new-function="addNewTag"
      multiple
      searchable
      allow-new-data
      new-data-text="AddTag"
      no-items-found-text="contacts.tags.select_filters_no_text"
      max-width="300"

  >
    <div  v-if="item.tags.length > 0" class="d-flex ">
      <v-hover #default="{hover}">
        <v-sheet
            :color="hover ? wsBACKGROUND : wsLIGHTCARD"
            class="pa-1 px-2 text-no-wrap"
            style="border-radius: 2px"
            max-width="200"
        >
          <h5 class="wsACCENT text-no-wrap"  >
            {{ tagsText }}
          </h5>
        </v-sheet>
      </v-hover>
    </div>
    <div v-else>
      <div v-if="hover" class="wsACCENT">

        <v-hover #default="{hover}">
          <v-sheet
              :color="hover ? wsBACKGROUND : wsLIGHTCARD"
              class="d-flex pa-1 px-2 pr-3 pointer"
              style="border-radius: 2px"
              v-ripple
          >
            <h5  class="wsACCENT text-no-wrap">
              <v-icon :color="wsACCENT">mdi-label-outline</v-icon>
              {{ $t('AddTag') }}
            </h5>
          </v-sheet>
        </v-hover>
      </div>

    </div>
  </ft-select>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "contactTagsTableItem",
  props : {
    item : {
      type : Object,
      default() { return {} }
    },
    tagsSelect : {
      type : Array,
      default() { return [] }
    },
    hover : {
      type : Boolean,
      default : false
    },
    contactId : {
      type : String,
      default : null
    }
  },
  computed : {
    tagsText() {
      let text = ''


      if ( !this.item.tags) {
        return ''
      }
      if (!this.item.tags.length) {
        return ''
      }

      let maxTextLength = 25;
      let displayedItems = 0;
      let displayedTextLength = 0;
      this.item.tags.forEach((tag,i) => {
        let coma = i < this.item.tags.length - 1 ? ', ' : ''

        if ( displayedTextLength < maxTextLength ) {
          text += this.tagsNames[tag] + coma
          displayedItems++;
          displayedTextLength = text.length
        }
      })

      let remained =  this.item.tags.length - displayedItems

      if ( text.length > maxTextLength ) {
        text = text.slice(0,maxTextLength) + '...'
      }
      if ( remained > 0 ) {
        text += ` +${remained}`
      }

      return  text
    },
    tagsNames() {
      let object = {}
      this.tagsSelect.forEach(item => {
        object[item.value] = item.text
      })

      return object
    },
  },
  methods : {
    ...mapActions( 'crmSystem', [
      'ADD_NEW_TAG',
    ]),

    ...mapActions( 'contacts', [
      'EDIT_CONTACT_TAGS'
    ]),



    async updateTags() {
      let data = {
        uuid : this.contactId || this.item.uuid,
        tags : this.item.tags
      }
      await this.EDIT_CONTACT_TAGS(data);
    },

    async addNewTag(name) {

      let data = {
        name: name
      }

      let result = await this.ADD_NEW_TAG(data)
      if ( !result || result === true ) {
        return
      }
      this.$emit('add-tag', result)
      return result
    }
  }

}
</script>



<style scoped>

</style>